import request from "@/utils/request"

//请求期权Deribit BTC期权标的方法
export function getDeribitRate(data) {
    return request({
        method: 'get',
        url: '/deribitOptionInstrument?bc=BTC',
        params: data,

    })

}
//请求期权Deribit ETH期权标的方法
export function getEthData(data) {
    return request({
        method: 'get',
        url: '/deribitOptionInstrument?bc=ETH',
        params: data,
    })

}

//请求期权Deribit期权持仓量 BTC期权交易持仓和成交额(行权日期)
export function getBtcExpiry(data) {
    return request({
        method: 'get',
        url: '/deribitOptionHoldings?type=1',
        params: data,
    })

}

//请求期权Deribit期权持仓量 BTC期权交易持仓和成交额(行权价)
export function getBtcPrice(data) {
    return request({
        method: 'get',
        url: '/deribitOptionHoldings?type=2',
        params: data,
    })

}

//请求期权Deribit期权持仓量 BTC期权交易持仓量(标的)
export function getBtcCurrency(data) {
    return request({
        method: 'get',
        url: '/deribitOptionHoldings?type=3',
        params: data,
    })

}